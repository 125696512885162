<template>
  <div class="">
    <!-- 头部设置下载跟上传 -->
    <div>
      <!-- 上传excel文件 -->
      <div style="margin-left:10px" class="UploadFile">
        <i class="el-icon-upload" />
        <p class="text">Please put the Execl document here</p>
        <p class="text">
          * Support JPG, XLSX, EML,MSG,TXT…, file size
        </p>
        <el-upload
          accept=".xlsx, .xls"
          :action="actionUrl"
          :data="datas"
          :on-success="uploadSuccess"
          :show-file-list="isShowFile"
          multiple
          :on-progress="handleProgress"
        >
          <div>
            <el-button
              type="primary"
              class="sl-icon-upload"
              style="height: 36px;"
              :loading="isLoading"
            >
              Upload batch file
            </el-button>
          </div>
        </el-upload>
        <!-- 下载excel文件 -->
        <div style="margin-top:10px">
          <a
            href="javascript:;"
            class="el-icon-download"
            style="font-size:14px;color:rgba(34,98,255,1);margin-top:8px"
            @click="downloadFile"
          >
            Download Excel Template
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from '@/api/VspProductApi/api';
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      // limitNum: 1, //选择文件个数
      datas: { creator: navigator.userAgent },
      isLoading:false,
      actionUrl:
        process.env.VUE_APP_BASE_API +
        '/system/sku/task/batchCreateSkuInfoTask', //上传文件url
      isShowFile: true, //文件列表是否显示 默认不显示
      excel: 'AddskuinfoTemplate.xlsx'
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    // 文件下载
    downloadFile() {
      let excel = this.excel;
      download(excel).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = this.excel;
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    handleProgress(){
      this.isLoading=true
    },
    //文件上传之前的钩子
    beforeUploadFile(file) {
      
      var FileExt = file.name.replace(/.+\./, '');
      if (['xls', 'xlsx'].indexOf(FileExt.toLowerCase()) === -1) {
        this.$message({
          type: 'warning',
          message: 'Please upload attachments with suffix XLS and xlsx！'
        });
        return false;
      }
      //file.size 以字节为单位
      this.isLt2k = file.size / 2048 < 100 ? '1' : '0';
      if (this.isLt2k === '0') {
        this.$message({
          message: 'The uploaded file size cannot exceed 100k!',
          type: 'error'
        });
      }
      return this.isLt2k === '1' ? true : false;
    },

    //文件上传成功时的钩子
    uploadSuccess(response, file, fileList) {
      this.isLoading=false
      this.$emit('rediong', 213213);
      // this.$emit('addlists', 'limitNum');
      console.log(response);
      //console.log('返回状态', response.code);
      //console.log(fileList);
      if (response.code == 200) {
        this.isShowFile = true;
        this.$message({
          type: 'success',
          message: 'Import success！'
        });
      } else {
        this.excelData = response.data;
        this.$message({
          type: 'error',
          message: 'Import failure！'
        });
        
      }
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.el-button {
  margin-top: 34px;
  padding: 0 50px;
}
.UploadFile {
  width: 1152px;
  height: 260px;
  background: rgba(183, 188, 201, 0.1);
  border: 1px solid rgba(36, 37, 38, 0.35);
  border-radius: 4px;
  margin: 0 auto !important;
  text-align: center;
}
/deep/ .text:nth-of-type(1) {
  font-size: 14px !important;
}
/deep/ .text:nth-of-type(2) {
  color: rgba(183, 188, 201, 0.5);
  border-radius: 4px;
  font-size: 14px !important;
}
/deep/ .el-icon-upload {
  font-size: 60px;
  color: rgb(128, 128, 128);
  margin-top: 0px;
}
</style>
