<template>
  <div class="productAdd">
    <template>
      <el-tabs v-model.trim="activeName" class="tabs" @tab-click="handleClick">
        <el-tab-pane label="SKU ID" name="first" class="first">
          <el-form ref="form" :model="form" label-width="140px">
            <div class="divAlert">
              <el-alert
                title="Only add the goods on the shelves"
                type="info"
                show-icon
              />
            </div>
            <!-- Product Pool SKY -->
            <el-form-item label="Product Pool SKU" class="poolSky">
              <el-input
                v-model.trim="form.res"
                onafterpaste="this.value=this.value.replace(/，/g,',')"
                onkeyup="this.value=this.value.replace(/，/g,',')"
                type="textarea"
                class="textarea"
                resize="none"
                placeholder="Please Enter"
              />
            </el-form-item>
          </el-form>
          <!-- <bottom @click="Submit"/> -->
          <div class="fixed">
            <el-button
              type="primary"
              size="small"
              class="buttons"
              @click="Submit"
            >
              Submit
            </el-button>
          </div>
        </el-tab-pane>
        <!-- 第二个切换 -->
        <el-tab-pane label="Batch upload" name="second">
          <batchUpload />
          <p class="UploadP">Upload History</p>
          <div class="operationLogo">
            <el-form
              ref="operationForm"
              :model="operationForm"
              :inline="true"
              label-width="90px"
            >
              <el-form-item label="Task ID" class="form-item" prop="id">
                <el-input
                  v-model.trim="operationForm.id"
                  oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                  class="select"
                  size="mini"
                  placeholder="Please Enter"
                />
              </el-form-item>
              <el-form-item
                label="Task Status"
                class="lineheight"
                prop="taskState"
              >
                <el-select
                  v-model.trim="operationForm.taskState"
                  placeholder="--Please Choose--"
                  size="mini"
                  class="select"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="Creator" class="form-item" prop="creator">
                <el-input
                  v-model.trim="operationForm.creator"
                  size="mini"
                  placeholder="--Please Enter--"
                />
              </el-form-item>
              <el-form-item class="FromButton">
                <el-button class="button" @click="onreset">Reset</el-button>
                <el-button
                  type="primary"
                  class="button"
                  icon="el-icon-search"
                  @click="onSearch"
                >
                  Search
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- 查看日志的table表格 -->
          <template>
            <div style="padding: 0 16px">
              <el-table
                border
                :data="tableData"
                style="width: 100%"
                class="tables"
                :header-cell-style="{ background: '#e8f1fe' }"
              >
                <!-- 修改无数据时 提示文字 -->

                <template slot="empty" class="logo_no">
                  <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
                  <p style="color: #969799">No more data</p>
                </template>
                <el-table-column prop="id" label="Task ID" width="100" />
                <el-table-column
                  prop="originFileUrl"
                  label="Task Name"
                  width="300"
                />
                <el-table-column
                  prop="created"
                  label="Task Start Time"
                  width="200"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.created || '--' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Success/Total" width="180">
                  <template slot-scope="scope">
                    <span>{{ scope.row.successCount }}</span>/<span> {{ scope.row.totalCount }}</span>
                  </template>
                </el-table-column>
                <!-- 状态管理 -->
                <el-table-column label="Task Status" width="180">
                  <template slot-scope="scope">
                    <font v-if="scope.row.taskState == 3">
                      <i class="el-icon-success" style="color: #40c740" />
                      Completed
                    </font>
                    <font v-if="scope.row.taskState == 2">
                      <i class="el-icon-error" style="color: #fa5050" />
                      Mistake
                    </font>
                    <font v-if="scope.row.taskState == 1">
                      <i class="el-icon-time" style="color: black" />
                      In progress
                    </font>
                  </template>
                </el-table-column>
                <el-table-column label="Created By" min-width="180">
                  <template slot-scope="scope">
                    <span>{{ scope.row.creator }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="operate"
                  label="Operate"
                  width="200"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="File(scope)"
                    >
                      Download File
                    </el-link>
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="Results(scope)"
                    >
                      Download Results
                    </el-link>
                  </template>
                </el-table-column>
              </el-table>
              <div class="pageWrapper">
                <el-pagination
                  :current-page="pages.currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pages.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pages.total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
            </div>
          </template>
          <div style="height: 33px" />
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
import batchUpload from './components/batch-upload.vue';
import {
  getBannerList,
  getAddsList,
  addSkuByIds,
  download,
} from '@/api/VspProductApi/api';
export default {
  name: 'Add',
  components: {
    batchUpload
    // CategorySelect
  },
  data() {
    return {
      emptyImgSrc: '',
      activeName: 'first',
      form: {
        res: null
      },
      // props: [
      //   {
      //     label: 'name',
      //     children: 'zones',
      //     isLeaf: 'leaf'
      //   }
      // ],
      isactive: false,
      // allSelectListsr: [],
      addData: null,
      // 查询表单数据
      operationForm: {},
      options: [
        {
          value: 0,
          label:'unexecuted'
        },
        {
          value: 2,
          label: 'mistake'
        },
        {
          value: 3,
          label: 'Completed'
        },
        {
          value: 1,
          label: 'In progress'

        }
      ],
      tableData: [],
      // 分页数据
      pages: {
        size: 10,
        currentPage: 1,
        total: 0
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      if (tab.name === 'first') {
        this.form.res = null;
        this.getLists();
        this.isactive = true;
      } else {
        this.isactive = false;
      }
      if (tab.name === 'second') {
        this.form.res = null;
        this.tableData = [];
        this.getLists();
        this.isactive = true;
      } else {
        this.isactive = false;
      }
    },
    getBannerLists() {
      getBannerList().then(res => {
        this.data = res.data;
      });
    },
    onConfirm() {},
    // 根据id添加到Commodity pools
    Submit() {
      if(!this.form.res){
        return this.$message({
          message :'Please fill in Product Pool SKU'
        })
      }
      const number = this.form.res.replace(/，/ig,',').split(',');
      let numbers = number.map(function(item) {
        return Number(item)
      }).filter((item)=>{return !(!item || item==='' )});
      if(numbers.length>500){
        return this.$message({
          message:'Up to 500 SKUs'
        })
      }
      addSkuByIds({
        skuIds: numbers,
      }).then(res => {
        if (res.code != 200) {
          this.$message({
            type: 'error',
            message: res.msg
          });
        } else {
          this.$message({
            type: 'success',
            message: 'Operation succeeded'
          });
        }
      });
    },
    // allSelectLists(data) {
    //   this.allSelectListsr = data;
    // },
    getLists() {
      getAddsList({
        batchSkuTaskDTO: this.operationForm,
        pageSize: this.pages.size,
        pageNo: this.pages.currentPage
      }).then(res => {
        if (res.success && res.code === 200) {
          this.tableData = res.data.dataList;
          this.pages.total = res.data.totalCount;
        } else {
          // 暂无数据
          this.tableData = [];
          this.pages.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    onSearch() {
      this.getLists();
    },
    onreset() {
      this.operationForm={}
      this.pages.size=10
      this.pages.currentPage=1
      this.getLists();
      // this.$refs.operationForm.resetFields();
    },
    handleSizeChange(val) {
      this.pages.size = val;
      this.getLists();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getLists();
    },
    //列表里面的操作
    File(row) {
      download(row.row.originFileUrl).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = row.row.originFileUrl + '.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    Results(row) {
      if (row.row.resultFileUrl) {
        let resultFileUrl = row.row.resultFileUrl;
        download(resultFileUrl)
          .then(res => {
            var downloadUrl = window.URL.createObjectURL(res);
            var anchor = document.createElement('a');
            anchor.href = downloadUrl;
            anchor.download = row.row.resultFileUrl;
            anchor.click();
            window.URL.revokeObjectURL(res);
          })
          .catch(error => {
            this.$message({
              type: 'error',
              message: 'There is no source file yet'
            });
          });
      } else {
        this.$message({
          message: 'The source file is empty',
          type: 'error'
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.productAdd {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ::v-deep .el-tabs__nav {
    margin-left: 40px;
  }
  background: white;
  .divAlert {
    margin: 20px 0 0 40px;
  }
  .poolSky {
    margin: 34px 0 0 30px;

    ::v-deep .el-textarea__inner {
      width: 732px;
      height: 372px;
    }
  }
  .from_flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    div {
      display: flex !important;
      flex-direction: column !important;
      /deep/.el-form-item__label {
        text-align: left !important;
      }
    }
  }
  .FromButton {
    margin-left: 50px;
  }
  .UploadP {
    margin-left: 12px;
    font-size: 16px;
    font-weight: Bold;
  }
  .textarea {
    min-width: 678px;
    min-height: 66px;
    font-size: 14px;
    font-family: ArialMT;
  }
  .first {
    height: 540px;
  }
  .add {
    border-radius: 10px;
  }
  .fixed {
    position: absolute;
    bottom: 0px;
    width: 100%;
    // height: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 1);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 999;
  }
  .buttons {
    width: 240px;
    height: 40px;
    background: rgba(34, 98, 255, 1);
    border-radius: 4px;
    font-size: 16px;
    font-family: ArialMT;
    margin: 20px 50%;
    transform: translateX(-50%);
  }
  .span {
    position: relative;
    bottom: -40px;
    left: 20px;
  }
  .operationLogo {
    width: 100%;
    .form-item {
      margin-left: 16px !important;
      /deep/ .el-input__inner {
        width: 200px !important;
      }
    }
    .lineheight {
      margin-left: 16px !important;
      ::v-deep .el-form-item__label {
        line-height: 18px;
      }
      /deep/ .el-input__inner {
        width: 200px !important;
      }
    }
  }
  .select {
    width: 200px;
  }
  .button {
    width: 100px !important;
    height: 32px !important;
    padding: 0 12px;
  }
  /deep/.el-button {
    // padding: 0 12px !important;
    //width: 100px;
    font-family: ArialMT;
    font-size: 14px;
  }

  ::v-deep .el-button--primary {
    color: #fff;
    background-color: #2262ff;
    border-color: #2262ff;
  }
  ::v-deep .el-button--text {
    color: #2262ff;
  }
  /* ::v-deep .el-link--primary {
    color: #2262ff;
  } */

  ::v-deep .el-radio-group {
    .el-radio__label {
      color: #242526;
    }
    .is-checked {
      .el-radio__inner {
        background-color: #2262ff;
      }
      .el-radio__label {
        color: #242526;
      }
    }
  }
  .button:first-child {
    margin-left: 26px;
  }
  ::v-deep .el-table {
    .cell {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }

  ::v-deep .el-pager {
    li {
      border: 0;
    }
  }
  .Action {
    /deep/ .el-form-item__label {
      margin-left: 16px;
      line-height: 18px !important;
    }
  }
  .pageWrapper {
    float: right;
    height: 50px;
    padding-top: 10px;
  }
}
</style>
